import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Globals } from '../../globals';
declare let transcend;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, AfterViewInit {
  @Input() mode: string;
  private menuId = 'footer-convergence';
  menu = [];
  private socialMenuId = 'footer_social';
  socialMenu: any;
  date: any;

  constructor(private menuService: MenuService, public globals: Globals) {
    this.mode = 'primary';
  }

  ngOnInit() {
    this.date = new Date().getFullYear();
    this.menuService.getMenu(this.menuId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.menu = menu.Items[0].items;
      }
    });

    this.menuService.getMenu(this.socialMenuId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.socialMenu = menu.Items[0].items;
      }
    });
  }

  ngAfterViewInit(): void {
    if (window['airgap']) {
      const cpraButton = document.getElementById('cpra_button');
      cpraButton.onclick = function () {
        transcend.showConsentManager({ viewState: 'CompleteOptionsToggles' });
      };

      cpraButton.style.display = 'inline-flex';
    }
  }
}
